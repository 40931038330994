    body {
        background-color: #fafafa;
        height: 90%
    }

    /* start of position css */
    .relative {
        position: relative !important;
    }

    .header-fixed {
        position: fixed
    }

    .absolute {
        position: absolute !important;
    }

    /* end of position css */

    /* start of z-index */
    .z-100 {
        z-index: 100 !important;
    }

    /* end of z-index */

    /* start of padding css */
    .pl-1p5rem {
        padding-left: 1.5rem !important;
    }

    .pr-1p5rem {
        padding-right: 1.5rem !important;
    }

    .px-1p5rem {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    /* end of padding css */
    .modal-container {
        height: fit-content;
    }

    /* start of padding css media queries */
    @media screen and (min-width: 727px) and (max-width: 999px) {
        .container {
            padding-left: 10% !important;
            padding-right: 0% !important;
        }

        .page-header-center {
            align-content: flex-start;
            display: flex;
            padding-left: 15% !important
        }
    }

    @media screen and (min-width: 450px) and (max-width: 727px) {
        .sm\:px-0 {
            padding-left: 0% !important;
            padding-right: 0% !important;
        }

        .container {
            padding-left: 0% !important;
            padding-right: 0% !important;
        }

        .page-header-center {
            align-content: flex-start;
            display: flex;
            padding-left: 10% !important;
            padding-right: 0% !important;
        }
    }

    @media screen and (max-width: 450px) {
        .page-header-center {
            align-content: center;
            display: flex;
            text-wrap: normal;
            padding-left: 0% !important
        }

        .xs\:px-0 {
            padding-left: 0% !important;
            padding-right: 0% !important;
        }
    }

    /* start of padding css media queries */

    /* start of margin css */
    .mt-10 {
        margin-top: 10px !important;
    }

    .mt-3rem {
        margin-top: 3rem !important;
    }

    .mt-4p {
        margin-top: 4% !important;
    }

    .mt-5p {
        margin-top: 5% !important;
    }

    .mb-0 {
        margin-bottom: 0px !important;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mb-3rem {
        margin-bottom: 3rem !important;
    }

    .mb-3p {
        margin-bottom: 3% !important;
    }

    .mb-4p {
        margin-bottom: 4% !important;
    }

    .mb-5p {
        margin-bottom: 5% !important;
    }

    .mb-3p5rem {
        margin-bottom: 3.5rem !important;
    }

    .mb-1\/10 {
        margin-bottom: 10% !important;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mr-5p {
        margin-right: 5% !important;
    }

    .mr-1\/10 {
        margin-right: 10% !important;
    }

    .ml-3\/10 {
        margin-left: 30% !important;
    }

    .ml-1\/3 {
        margin-left: 33.33% !important;
    }

    .ml-2\/5 {
        margin-left: 40% !important;
    }

    .ml-1\/2 {
        margin-left: 50% !important;
    }

    /* end of margin css */

    /* start of width css */
    .w-1\/10 {
        width: 10% !important;
    }

    .w-1\/5 {
        width: 20% !important;
    }

    .w-1\/4 {
        width: 25% !important;
    }

    .w-1\/3 {
        width: 33.33% !important;
    }

    .w-2\/5 {
        width: 40% !important;
    }

    .w-1\/2 {
        width: 50% !important;
    }

    .w-3\/5 {
        width: 60% !important;
    }

    .w-3\/4 {
        width: 75% !important;
    }

    .w-4\/5 {
        width: 80% !important;
    }

    .w-full {
        width: 100% !important;
    }

    /* end of width css */

    /* start of media queries width css */
    @media screen and (min-width: 450px) and (max-width: 727px) {
        .sm\:w-2\/5 {
            width: 40% !important;
        }

        .sm\:w-3\/5 {
            width: 60% !important;
        }

        .sm\:w-4\/5 {
            width: 80% !important;
        }

        .sm\:w-full {
            width: 100% !important;
        }
    }

    @media screen and (max-width: 450px) {
        .xs\:w-2\/5 {
            width: 40% !important;
        }

        .xs\:w-3\/5 {
            width: 60% !important;
        }

        .xs\:w-4\/5 {
            width: 80% !important;
        }

        .xs\:w-full {
            width: 100% !important;
        }

        .nav-button-next {
            float: left !important;
        }

        .nav-button-back {
            float: left !important;
        }
    }

    /* end of media queries width css */

    /* start of font css */
    .font-bold {
        font-weight: bold !important;
    }

    .font-bolder {
        font-weight: bolder !important;
    }

    .text-black {
        color: black !important
    }

    /* end of font css */

    /* start of display css */
    .none {
        display: none !important;
    }

    /* end of display css */

    /* start of float css*/
    .float-left {
        float: left !important;
    }

    /* end of float css*/

    /* start of size css */
    .font-size-50 {
        font-size: 50px !important;
    }

    /* end of size css */

    /* start of custom css classes */
    .disclaimer {
        display: flex !important;
    }

    div[disabled] {
        pointer-events: none;
        opacity: 0.5;
    }

    .disclaimer-icon {
        margin-right: 10px !important;
    }

    .btn-pull-center {
        left: 40% !important;
    }

    .btn-pull-right {
        left: 75% !important;
    }

    .accordian-header-text {
        padding-top: 3.3rem !important;
        padding-bottom: 3.3rem !important;
        font-size: 2rem !important;
        font-weight: bold !important;
    }

    .active-accordion-content-container {
        display: block !important;
    }

    .autocomplete-input-suggestion {
        font-size: 1.4rem !important;
        padding: 4px !important;
        color: #215bb0 !important;
        background-color: white !important;
        position: relative !important;
    }

    .autocomplete-input-suggestion:hover,
    .hovered-suggestion,
    .hovered-suggestion:hover {
        cursor: pointer !important;
        font-size: 1.4rem !important;
        font-weight: bold !important;
        padding: 4px !important;
        color: #194b93 !important;
        border-radius: 0.4rem !important;
        background-color: #ededed !important;
        position: relative !important;
    }

    input::placeholder {
        color: #bbb;
    }

    input:focus-within::placeholder {
        color: transparent;
    }

    .search-container {
        display: flex;
    }

    .search-container>.loader {
        border: 2px solid #f3f3f3;
        border-radius: 50%;
        border-top: 2px solid #3773ca;
        max-width: 2.5rem;
        max-height: 2.5rem;
        position: relative;
        right: 3rem;
        top: .8rem;
        -webkit-animation: spinner .8s linear infinite;
        /* Safari */
        animation: spinner .8s linear infinite;
    }

    .page-header-left {
        /* Small/H2 */
        font-family: 'GeicoRoobert';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0px;
        display: flex;
        justify-content: left;
        color: #1C1C1E;
    }

    .page-header-center {
        /* Small/H2 */
        font-family: 'GeicoRoobert';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: center;
        color: #1C1C1E;
    }

    .privacy-container {
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        align-items: flex-start;
        gap: 15px;
        background: #ffffff;
        border: 1px solid #EDEDED;
        border-radius: 16px;
        padding: 1.8rem;
        margin: 1rem;
    }

    .privacy-header {
        display: flex;
        flex-direction: column;
        font-family: 'GeicoRoobert';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #1C1C1E;
    }

    .privacy-content {
        font-family: 'GeicoRoobert';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #1C1C1E;
        align-self: stretch;
    }

    .dropdown-disabled {
        cursor: not-allowed !important;
        background-color: #ededed !important;
        border: 1px solid #757374 !important;
    }

    .input-disabled {
        cursor: not-allowed !important;
        background-color: #ededed !important;
        border: 1px solid #757374 !important;
    }

    .error-message {
        color: #b6091a !important;
        display: block !important;
        font-size: 1.4rem !important;
        float: left !important;
    }

    .form-field--error .form-message {
        margin-bottom: 0px !important;
    }

    /*.active-image-card {
    border: 3px solid #3773CA !important;
}
*/
    .form-field-wrapper {
        margin-right: 1% !important;
        margin-left: 1% !important;
        margin-bottom: 1% !important;
    }

    .zip-code-geo-icon {
        top: 0px !important;
        left: 0px !important;
        font-size: 2.5rem !important;
        height: 4.4rem !important;
        width: 4rem !important;
        position: relative !important;
        border-top: 0.1rem !important;
        border-bottom: 0.1rem !important;
        border-right: 0.1rem !important;
        border-left: 0px !important;
        border-style: solid !important;
        border-top-right-radius: 0.4rem !important;
        border-bottom-right-radius: 0.4rem !important;
        border-color: #222 !important;
    }

    .image-selection-wrapper {
        user-select: none;
    }

    .image-selections {
        max-width: 100% !important;
    }

    .image-selection-card {
        place-content: start !important;
        min-width: 280px;
    }

    .image-selections-image {
        background-color: #ffffff !important;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: .1rem;
        place-content: start !important;
    }

    .image-selections-image-disabled {
        background-color: #ededed !important;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0px;
        margin-bottom: 1rem;
        place-content: start !important;
    }

    .image-selection-card-error {
        border: 2px solid #b6091a !important;
    }

    .image-selection-card-disabled {
        margin-right: 1% !important;
        margin-left: 1% !important;
        background-color: #ededed !important;
        cursor: not-allowed !important;
        border: 1px solid #757374 !important;
    }

    .image-card-description {
        font-size: 13px !important;
        margin: 0px 0px 0px 0px;
        line-height: 2rem !important;
    }

    .image-card-item-container {
        margin: 0px 10px 0px 10px;
        min-height: 80px;
    }

    .image-card-item {
        font-size: 13px !important;
        padding-left: 1.20rem !important;
        padding-right: 1.20rem !important;
        margin: 4px;
        list-style: inherit !important;
        line-height: 1.5rem !important;
    }

    .image-card-image-disabled {
        color: #757374 !important;
        cursor: not-allowed !important;
    }

    .image-card-checkbox::before {
        content: "";
        position: absolute;
        display: block;
        background-color: #fff;
        border: .2rem solid #757374;
        height: 2.2rem;
        width: 2.2rem;
        bottom: .6rem;
        right: .5rem;
        border-radius: .4rem;
    }

    .image-card-label {
        font-weight: bolder !important;
        margin-bottom: 0px !important;
        font-size: 1.8rem !important;
    }

    .image-card-label-disabled {
        font-weight: bold !important;
        color: #757374 !important;
        cursor: not-allowed !important;
    }

    .autocomplete-suggestions-container {
        overflow: auto !important;
        max-height: 25rem;
        position: absolute !important;
        z-index: 100 !important;
        border: 0.1rem solid #ededed !important;
        border-radius: 0.4rem !important;
    }

    .geico-logo {
        left: 10% !important;
        font-size: 14rem !important;
        overflow: inherit !important;
    }

    .radio-button-wrapper {
        display: inherit !important;
    }

    .display-contents {
        display: contents !important;
    }

    .float-inherit {
        float: inherit !important;
    }

    .show-more {
        font-weight: 600 !important;
        text-decoration: underline !important;
        color: #3773ca !important;
        cursor: pointer !important;
        outline: 0 !important;
        margin-left: 1% !important;
        padding-left: 2.5rem;
    }

    /* end of custom css classes */

    /* start of custom css media query */

    @media screen and (min-width: 727px) {
        .nav-button-next {
            float: left !important;
        }

        .nav-button-back {
            float: right !important;
        }
    }

    @media screen and (min-width: 450px) and (max-width: 727px) {
        .btn-pull-right {
            left: 35% !important;
        }

        .btn-pull-center {
            left: 30% !important;
        }

        .image-selection-wrapper .image-selection-card {
            width: 45% !important;
            place-content: start !important;
            min-height: fit-content;
            min-width: 280px;
        }

        .image-selections {
            place-content: center !important;
        }

        .header-fixed {
            position: fixed
        }
    }

    @media screen and (max-width: 450px) {
        .btn-pull-right {
            left: 35% !important;
        }

        .pb-btn-pull-center {
            left: 30% !important;
        }

        .image-selection-wrapper .image-selection-card {
            width: 95% !important;
            place-content: center !important;
            height: min-content !important;
        }

        .image-selections {
            place-content: center !important;
        }
    }

    .user-name-lable {
        width: 100%;
        display: flex;
        font-size: 1.4rem;
        color: #1c1c1e;
        text-decoration: none;
        font-weight: 700;
        align-items: center;
        padding: 1rem 1rem 1rem 1rem;
    }

    #title-bar {
        height: 46px;
        font-style: bold;
        text-align: center;
        font-size: 18px;
        color: #FFF;
        background-color: #004B9B;
        padding-top: 9px;
    }

    .application-name {
        width: 50%;
        display: flex;
        font-size: 1.4rem;
        color: #1c1c1e;
        text-decoration: none;
        font-weight: 700;
        align-items: center;
        padding: 1rem 1rem 1rem 1rem;
        margin-right: auto;
    }

    .search-quote-result-container {
        max-width: 80%;
        margin: auto;
        padding: "1rem";
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        overflow: auto;
    }

    .modal-content {
        background: rgb(255, 255, 255);
        padding: 0;
        border-radius: 8px;
        position: relative;
        width: 90%;
        max-width: 80%;
        max-height: 90%;
        overflow-y: auto;
    }
    .alert-success {
        background-color: #d4edda;
        color: #155724; 
        padding: 10px; 
        margin: 10px 0; 
        border-radius: 4px; 
        text-align: left;
        font-size: 16px; 
        box-shadow: none; 
        border: none;
    }